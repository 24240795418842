p {
  margin: 0px;
}

.label-2 {
  margin-top: 0;
  color: #99cccc;
}

.hr {
  width: 100%;
  border: 1px solid #6699cc;
  margin-bottom: 26px;
}
