.label-1 {
  line-height: 24px;
  margin-bottom: 12px;
  text-align: left;
}

.label-2 {
  line-height: 24px;
  margin-bottom: 12px;
  color: #99cccc;
}
